import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { FaMedium } from 'react-icons/fa';
import { FaReddit } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa';
import { FaGithub } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

const styles = (theme) => ({
  root: {
    gridArea: 'footer',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 40,
  },
  rightBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0 10px 5px 10px',
  },
  icon: {
    fontSize: 15,
    color: '#fff',
    margin: 4,
    padding: 10,
    lineHeight: 0,
    '& :hover': {
      '-o-transition': '0.5s',
      '-ms-transition': '0.5s',
      '-moz-transition': '0.5s',
      '-webkit-transition': '0.5s',
      transition: '0.5s',
    },
  },
  mediumIcon: {
    backgroundColor: '#66cdaa',
    '&:hover': {
      backgroundColor: '#478f76',
    },
  },
  facebookIcon: {
    backgroundColor: '#3b5998',
    '&:hover': {
      backgroundColor: '#8b9dc3',
    },
  },
  xTwitterIcon: {
    backgroundColor: '#000',
    '&:hover': {
      backgroundColor: '#333',
    },
  },
  redditIcon: {
    backgroundColor: '#ff5700',
    '&:hover': {
      backgroundColor: '#ff8700',
    },
  },
  linkedinIcon: {
    backgroundColor: '#0073a4',
    '&:hover': {
      backgroundColor: '#00aced',
    },
  },
  githubIcon: {
    backgroundColor: '#333',
    '&:hover': {
      backgroundColor: '#444',
    },
  },
  youtubeIcon: {
    backgroundColor: '#cc181e',
    '&:hover': {
      backgroundColor: '#e64a41',
    },
  },
});

const Footer = ({ classes, notify }) => {
  return (
    <div className={classes.root}>
      <div className={classes.rightBox}>
        <div className={classes.row}>
          <a
            className={classNames(classes.icon, classes.mediumIcon)}
            href="https://medium.com/iex-ec"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FaMedium />
          </a>
          <a
            className={classNames(classes.icon, classes.xTwitterIcon)}
            href="https://twitter.com/iEx_ec"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FaXTwitter />
          </a>
          <a
            className={classNames(classes.icon, classes.redditIcon)}
            href="https://www.reddit.com/r/iexec/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FaReddit />
          </a>
          <a
            className={classNames(classes.icon, classes.linkedinIcon)}
            href="https://www.linkedin.com/company/10981269/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FaLinkedin />
          </a>
          <a
            className={classNames(classes.icon, classes.githubIcon)}
            href="https://github.com/iExecBlockchainComputing"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FaGithub />
          </a>
          <a
            className={classNames(classes.icon, classes.youtubeIcon)}
            href="https://www.youtube.com/channel/UCwWxZWvKVHn3CXnmDooLWtA"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FaYoutube />
          </a>
        </div>
        <div className={classes.row}>
          <a href="https://iex.ec">{'https://iex.ec'}</a>
        </div>
        <div className={classes.row}>
          All Rights Reserved {new Date().getFullYear()}
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(Footer);
